/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/fontawesome-v5'



import {Notify,Loading,Dialog,AppVisibility} from 'quasar'



export default { config: {"notify":{"color":"green","closeBtn":true,"multiLine":true,"timeout":10000,"attrs":{"role":"alertdialog"},"classes":"my-notif-class","badgePosition":"top-left","position":"bottom-right","type":"info"},"loading":{"delay":125,"message":"Loading"}},iconSet,plugins: {Notify,Loading,Dialog,AppVisibility} }


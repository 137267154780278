import md5 from 'blueimp-md5'
import Base64 from 'Base64'
export function someAction (/* context */) {
}

export function testAction(context) {
    const password = 'test';
    const email = 'test@test.com';
    console.log(Base64.btoa(md5(password, email, true)));
}

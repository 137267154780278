const TITLE_TAGLINE = 'Adopt brands for foreign markets';

const routes = [

  { path: '/', meta: { title: `MatchSell | ${ TITLE_TAGLINE }` }, component: () => import('layouts/WrapperForLandingPages.vue'),
    children: [
      { path: '', component: () => import('pages/landing/') },
      { path: 'help-contact-us', meta: { title: 'Help - MatchSell' }, component: () => import('pages/landing/') },

      { path: 'about', meta: { title: 'About MatchSell' }, component: () => import('pages/about.vue') },
      { path: 'contact-and-support', meta: { title: 'Contact and Support' }, component: () => import('pages/contact-and-support.vue') },
      { path: 'terms-and-conditions', meta: { title: 'Terms and Conditions' }, component: () => import('pages/terms-and-conditions.vue') },
      { path: 'press', meta: { title: 'Press' }, component: () => import('pages/press.vue') },
      { path: 'investors', meta: { title: 'Investors' }, component: () => import('pages/investors.vue') },
      { path: 'partners', meta: { title: 'Partners' }, component: () => import('pages/partners.vue') },
      { path: 'faq', meta: { title: 'Frequently Asked Questions' }, component: () => import('pages/faq.vue') },
      { path: 'social', meta: { title: 'Social Media' }, component: () => import('pages/social.vue') },


      { path: 'login', meta: { title: 'Log in' }, component: () => import('pages/login.vue') },

      { path: '/registration-for-brands-and-manufacturers', meta: { title: 'Register as a Brand / Manufacturer' },
                component: () => import('pages/register-bm.vue') },

      { path: '/registration-for-service-providers', meta: { title: 'Register as a Service Provider' },
                component: () => import('pages/register-bm.vue') },


       { path: '/products/1', meta: { title: 'Service Provider Products' }, component: () => import('pages/sp-products-main.vue') },

      // { path: '', meta: { title: '' }, component: () => import('pages/.vue') },

    ]
  },

  /*
  { path: '/login', component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', meta: { title: 'Log in' }, component: () => import('pages/login.vue') },
    ],
  },
  */

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/error404.vue')
  }
]

export default routes
